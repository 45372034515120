import {storageKey} from 'store/constant'
import ApiService from 'utils/service'

export const getListKeluarga = async ({nip}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(`${process.env.REACT_APP_API_URL}/tbl_d_keluarga/${nip}`, config)
}
export const getListPegawai = async ({search, page, limit}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	let url = `${process.env.REACT_APP_API_URL}/tabel_pgw/autocomplete?`

	if (search) {
		url += `&search=${search}`
	} else if (page && limit) {
		url += `search=&page=${page}&per_page=${limit}`
	}

	return ApiService.get(url, config)
}
export const getWorkingDays = async ({startDate, endDate}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(
		`${process.env.REACT_APP_API_URL}/tbl_hari_kerja/working_day?start_date=${startDate}&end_date=${endDate}`,
		config
	)
}
export const getUnitKerja = async ({page, limit, search}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	let url = `${process.env.REACT_APP_API_URL}/tbl_r_unker?`

	if (search) {
		url += `&search=${search}&per_page=200`
	} else if (page && limit) {
		url += `search=&page=${page}&per_page=${limit}`
	}

	return ApiService.get(url, config)
}
export const getJabatan = async ({page, limit, search}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	let url = `${process.env.REACT_APP_API_URL}/tbl_r_jabatan?`

	if (search) {
		url += `&search=${search}&per_page=200`
	} else if (page && limit) {
		url += `search=&page=${page}&per_page=${limit}`
	}

	return ApiService.get(url, config)
}
export const getNegara = async ({page, limit}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(
		`${process.env.REACT_APP_API_URL}/tbl_negara?page=${page}&per_page=${limit}`,
		config
	)
}
export const getAgama = async () => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(`${process.env.REACT_APP_API_URL}/tabel_agama?search`, config)
}
export const getAlasanCuti = async ({jenisCuti}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(
		`${process.env.REACT_APP_API_URL}/master_alasan_cuti?jenis_cuti=${jenisCuti}`,
		config
	)
}
export const getStatusPernikahan = async () => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(`${process.env.REACT_APP_API_URL}/tbl_kawin`, config)
}
export const getPernikahanKe = async ({nip}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(
		`${process.env.REACT_APP_API_URL}/tbl_d_keluarga/pernikahan_ke/${nip}`,
		config
	)
}
export const getAnakKe = async ({nip}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(
		`${process.env.REACT_APP_API_URL}/tbl_d_keluarga/anak_kandung_ke/${nip}`,
		config
	)
}
export const getAnakAngkatKe = async ({nip}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(
		`${process.env.REACT_APP_API_URL}/tbl_d_keluarga/anak_angkat_ke/${nip}`,
		config
	)
}
export const getPegawaibyNIP = async ({nip}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(`${process.env.REACT_APP_API_URL}/tabel_pgw/kemenlu/${nip}`, config)
}
export const getUnitKerjaForRegister = async ({kategori}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	return ApiService.get(
		`${process.env.REACT_APP_API_URL}/tbl_r_unker/list-user?search=&kategori=${kategori}`,
		config
	)
}
export const getPegawaiByUnker = async ({page, limit, search}) => {
	const config = {
		headers: {Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`}
	}

	let url = `${process.env.REACT_APP_API_URL}/tabel_pgw/list-user?`

	if (search) {
		url += `&search=${search}&page=1&per_page=200`
	} else if (page && limit) {
		url += `search=&page=${page}&per_page=${limit}`
	}

	return ApiService.get(url, config)
}
