import {store, persister} from 'store'
import {BrowserRouter} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'

import ReactDOM from 'react-dom/client'
import * as serviceWorker from 'serviceWorker'
import App from 'App'
import config from 'config'
import 'assets/scss/style.scss'

// ==============================|| REACT DOM RENDER  ||============================== //

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persister}>
			<BrowserRouter basename={config.basename}>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
