import reducer from './reducer'
import {persistStore} from 'redux-persist'
import {configureStore} from '@reduxjs/toolkit'

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
	reducer,
	devTools: process.env.NODE_ENV !== 'production'
})
const persister = persistStore(store)

export {store, persister}
