import {Stack} from '@mui/material'

import AuthWrapper from '../AuthWrapper'
import AuthCardWrapper from '../AuthCardWrapper'
import AuthLogin from '../auth-forms/AuthLogin'

// ================================|| AUTH3 - LOGIN ||================================ //

// eslint-disable-next-line react/prop-types
const Login = ({isUnderMaintenance = false}) => (
	<AuthWrapper>
		<Stack justifyContent='center' alignItems='center' sx={{minHeight: '100vh'}}>
			<AuthCardWrapper>
				<AuthLogin isUnderMaintenance={isUnderMaintenance} />
			</AuthCardWrapper>
		</Stack>
	</AuthWrapper>
)

export default Login
