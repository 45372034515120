import {menuItems} from 'routes/routeHelper'
import {lazy} from 'react'
import {useSelector} from 'react-redux'

import Loadable from 'ui-component/Loadable'
import NoAccess from 'views/pages/maintenance/NoAccess'

const MasterRole = Loadable(lazy(() => import('views/pages/masterData/masterRole')))
const RoleForm = Loadable(lazy(() => import('views/pages/masterData/masterRole/form/AddRoleForm')))
// const KGB = Loadable(lazy(() => import('views/pages/KGB/pengajuanKGB')))
// const PengesahanKGB = Loadable(lazy(() => import('views/pages/KGB/pengesahanKGB')))
const PengesahanCuti = Loadable(
	lazy(() => import('views/pages/Cuti/pengesahanCuti/permohonanCuti'))
)
const PengesahanCLTN = Loadable(lazy(() => import('views/pages/Cuti/pengesahanCuti/cltn')))
const LogActivity = Loadable(lazy(() => import('views/pages/logActivity')))
const MasterCutiPegawai = Loadable(lazy(() => import('views/pages/masterData/masterCutiPegawai')))
const MasterPangkatGolongan = Loadable(lazy(() => import('views/pages/masterData/masterGaji')))
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')))
const AuthGuard = Loadable(lazy(() => import('utils/route-guard/AuthGuard')))
const FormPengesahanCuti = Loadable(
	lazy(() => import('components/form/permohonan/FormPengesahanCuti'))
)
const FormPengesahanCLTN = Loadable(
	lazy(() => import('components/form/permohonan/FormPengesahanCLTN'))
)
// const FormPengesahanKGB = Loadable(
// 	lazy(() => import('components/form/permohonan/FormPengesahanKGB'))
// )
const CjKelahiran = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/kelahiran')))
const CjPengangkatanAnak = Loadable(
	lazy(() => import('views/pages/cacahJiwa/pengajuan/pengangkatanAnak'))
)
const CjPerceraian = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/perceraian')))
const CjKematian = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/kematian')))
const CjPernikahan = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/pernikahan')))
const IzinMenikah = Loadable(lazy(() => import('views/pages/izinMenikah/pengajuan')))
const PengesahanCj = Loadable(lazy(() => import('views/pages/cacahJiwa/pengesahan')))
const FormPengesahanCj = Loadable(lazy(() => import('components/form/permohonan/FormPengesahanCj')))
const PengesahanIzinMenikah = Loadable(lazy(() => import('views/pages/izinMenikah/pengesahan')))
const FormPengesahanIzinMenikah = Loadable(
	lazy(() => import('components/form/permohonan/izinMenikah/FormPengesahanIzinMenikah'))
)
const PengajuanCuti = Loadable(lazy(() => import('views/pages/Cuti/pengajuanCuti')))
const RiwayatPengajuanCuti = Loadable(
	lazy(() => import('views/pages/Cuti/pengajuanCuti/riwayatPengajuan'))
)
const PageNotFound = Loadable(lazy(() => import('views/pages/maintenance/Error')))
const MasterUser = Loadable(lazy(() => import('views/pages/masterData/masterUser')))

// ==============================|| MAIN ROUTING ||============================== //

const ProtectedRoute = ({path, element}) => {
	const {
		role: {permissions: userPermissions}
	} = useSelector(state => state.account)

	const menuItem = menuItems.items.find(item => item.url === path)

	if (path !== '*') {
		if (!menuItem || !menuItem.permissions) {
			return <NoAccess />
		}

		const hasPermission = menuItem.permissions.some(permission =>
			userPermissions.some(userPermission => userPermission.permission_name === permission)
		)

		return hasPermission ? element : <NoAccess />
	} else {
		return element
	}
}

const routeConfig = [
	// #region IZIN MENIKAH
	// IZIN MENIKAH - PENGAJUAN
	{
		path: '/pengajuan-izin-menikah',
		element: <IzinMenikah />
	},
	// IZIN MENIKAH - PENGESAHAN
	{
		path: '/pengesahan-izin-menikah',
		element: <PengesahanIzinMenikah />
	},
	{
		path: '/pengesahan-izin-menikah/:id',
		element: <FormPengesahanIzinMenikah />
	},
	// #endregion

	// #region CACAH JIWA - PENGAJUAN
	{
		path: '/pengajuan-cacah-jiwa/kelahiran',
		element: <CjKelahiran />
	},
	{
		path: '/pengajuan-cacah-jiwa/pengangkatan-anak',
		element: <CjPengangkatanAnak />
	},
	{
		path: '/pengajuan-cacah-jiwa/pernikahan',
		element: <CjPernikahan />
	},
	{
		path: '/pengajuan-cacah-jiwa/perceraian',
		element: <CjPerceraian />
	},
	{
		path: '/pengajuan-cacah-jiwa/kematian',
		element: <CjKematian />
	},
	// #endregion
	// #region CACAH JIWA - PENGESAHAN
	{
		path: '/pengesahan-cacah-jiwa/kelahiran',
		element: <PengesahanCj val={0} />
	},
	{
		path: '/pengesahan-cacah-jiwa/pengangkatan-anak',
		element: <PengesahanCj val={1} />
	},
	{
		path: '/pengesahan-cacah-jiwa/pernikahan',
		element: <PengesahanCj val={2} />
	},
	{
		path: '/pengesahan-cacah-jiwa/perceraian',
		element: <PengesahanCj val={3} />
	},
	{
		path: '/pengesahan-cacah-jiwa/kematian',
		element: <PengesahanCj val={4} />
	},
	{
		path: '/pengesahan-cacah-jiwa/kelahiran/:id',
		element: <FormPengesahanCj />
	},
	{
		path: '/pengesahan-cacah-jiwa/pengangkatan-anak/:id',
		element: <FormPengesahanCj />
	},
	{
		path: '/pengesahan-cacah-jiwa/pernikahan/:id',
		element: <FormPengesahanCj />
	},
	{
		path: '/pengesahan-cacah-jiwa/perceraian/:id',
		element: <FormPengesahanCj />
	},
	{
		path: '/pengesahan-cacah-jiwa/kematian/:id',
		element: <FormPengesahanCj />
	},
	// #endregion

	// #region CUTI - PENGAJUAN
	{
		path: '/pengajuan-cuti/cuti-tahunan',
		element: <PengajuanCuti cuti='tahunan' />
	},
	{
		path: '/pengajuan-cuti/cuti-besar',
		element: <PengajuanCuti cuti='besar' />
	},
	{
		path: '/pengajuan-cuti/cuti-sakit',
		element: <PengajuanCuti cuti='sakit' />
	},
	{
		path: '/pengajuan-cuti/cuti-melahirkan',
		element: <PengajuanCuti cuti='melahirkan' />
	},
	{
		path: '/pengajuan-cuti/cuti-karena-alasan-penting',
		element: <PengajuanCuti cuti='penting' />
	},
	{
		path: '/pengajuan-cuti/cuti-di-luar-tanggungan-negara',
		element: <PengajuanCuti cuti='cltn' />
	},
	// #endregion
	// #region CUTI - PENGESAHAN
	{
		path: '/pengesahan-cuti/tahunan',
		element: <PengesahanCuti val={0} />
	},
	{
		path: '/pengesahan-cuti/besar',
		element: <PengesahanCuti val={1} />
	},
	{
		path: '/pengesahan-cuti/sakit',
		element: <PengesahanCuti val={2} />
	},
	{
		path: '/pengesahan-cuti/melahirkan',
		element: <PengesahanCuti val={3} />
	},
	{
		path: '/pengesahan-cuti/karena-alasan-penting',
		element: <PengesahanCuti val={4} />
	},
	{
		path: '/pengesahan-cuti/cltn',
		element: <PengesahanCLTN val={0} />
	},
	{
		path: '/pengesahan-cuti/perpanjangan-cltn',
		element: <PengesahanCLTN val={1} />
	},
	{
		path: '/pengesahan-cuti/lapor-selesai-cltn',
		element: <PengesahanCLTN val={2} />
	},
	{
		path: '/pengesahan-cuti/cltn-aktif',
		element: <PengesahanCLTN val={3} />
	},
	{
		path: '/pengesahan-cuti/tahunan/:id',
		element: <FormPengesahanCuti />
	},
	{
		path: '/pengesahan-cuti/besar/:id',
		element: <FormPengesahanCuti />
	},
	{
		path: '/pengesahan-cuti/sakit/:id',
		element: <FormPengesahanCuti />
	},
	{
		path: '/pengesahan-cuti/melahirkan/:id',
		element: <FormPengesahanCuti />
	},
	{
		path: '/pengesahan-cuti/karena-alasan-penting/:id',
		element: <FormPengesahanCuti />
	},
	{
		path: '/pengesahan-cuti/cltn/:id',
		element: <FormPengesahanCLTN />
	},
	{
		path: '/pengesahan-cuti/perpanjangan-cltn/:id',
		element: <FormPengesahanCLTN />
	},
	{
		path: '/pengesahan-cuti/lapor-selesai-cltn/:id',
		element: <FormPengesahanCLTN />
	},
	{
		path: '/pengesahan-cuti/cltn-aktif/:id',
		element: <FormPengesahanCLTN />
	},
	// #endregion

	// RWY PENGAJUAN CUTI
	{
		path: '/riwayat-pengajuan-cuti',
		element: <RiwayatPengajuanCuti />
	},

	// #region MASTER DATA
	{
		path: '/master-data/master-cuti-pegawai',
		element: <MasterCutiPegawai />
	},
	{
		path: '/master-data/master-kgb',
		element: <MasterPangkatGolongan />
	},
	{
		path: '/master-data/master-role',
		element: <MasterRole />
	},
	{
		path: '/master-data/master-user',
		element: <MasterUser />
	},
	{
		path: '/master-data/master-role/form',
		element: <RoleForm />
	},
	// #endregion

	// LOG
	{
		path: '/log-aktivitas',
		element: <LogActivity />
	},

	{
		path: '*',
		element: <PageNotFound />
	}
]

const MainRoutes = {
	path: '/',
	element: (
		<AuthGuard>
			<MainLayout />
		</AuthGuard>
	),
	children: routeConfig.map(({path, element}) => ({
		path,
		element: <ProtectedRoute path={path} element={element} />
	}))
}

export default MainRoutes
