export const gridSpacing = 3
export const drawerWidth = 200

export const currentYear = new Date().getFullYear()
export const jenisKelamin = ['Laki-laki', 'Perempuan']
export const msgErrGet = 'Gagal memuat data. Harap coba lagi.'
export const msgErrCreate = 'Gagal menambahkan data. Harap coba lagi.'
export const msgErrUpdate = 'Pembaruan data gagal. Harap coba lagi.'
export const msgErrDelete = 'Gagal menghapus data. Harap coba lagi.'
export const msgErrDok = 'Gagal memuat dokumen. Harap coba lagi.'
export const msgErrTTE = 'Pembubuhan TTE Tidak Berhasil. Harap coba lagi.'

export const storageKey = {
	USER_TOKEN: 'userToken'
}
