// project imports
import config from 'config'

// action - state management
import * as actionTypes from './actions'

export const initialState = {
	isOpen: [],
	fontFamily: config.fontFamily,
	borderRadius: config.borderRadius,
	outlinedFilled: config.outlinedFilled,
	navType: config.theme,
	presetColor: config.presetColor,
	locale: config.i18n,
	rtlLayout: config.rtlLayout,
	opened: true,

	counts: [0, 0, 0, 0] // izin menikah, cacah jiwa, cuti, cltn
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
	let id
	switch (action.type) {
		case actionTypes.MENU_OPEN:
			id = action.id
			return {
				...state,
				isOpen: [id]
			}
		case actionTypes.THEME_LOCALE:
			return {
				...state,
				locale: action.locale
			}
		case actionTypes.SET_MENU:
			return {
				...state,
				opened: action.opened
			}
		case actionTypes.UPDATE_COUNTS:
			return {
				...state,
				counts: action.payload
			}
		case actionTypes.DECREMENT_COUNT:
			return {
				...state,
				counts: state.counts.map((count, index) =>
					index === action.payload ? count - 1 : count
				)
			}
		default:
			return state
	}
}

export default customizationReducer
