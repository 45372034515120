import {styled} from '@mui/material/styles'
import {CardContent, Grid, Typography} from '@mui/material'
import {gridSpacing} from 'store/constant'

import MainCard from 'ui-component/cards/MainCard'

const ErrorWrapper = styled('div')({
	maxWidth: 350,
	margin: '0 auto',
	textAlign: 'center'
})

const NoAccess = () => {
	return (
		<MainCard
			content={false}
			sx={{height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			<CardContent>
				<ErrorWrapper>
					<Grid container spacing={gridSpacing}>
						<Grid item xs={12}>
							<Typography variant='h1' component='div' color='red'>
								Akses Ditolak
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='body2'>
								Anda tidak memiliki izin untuk mengakses halaman ini.
							</Typography>
						</Grid>
					</Grid>
				</ErrorWrapper>
			</CardContent>
		</MainCard>
	)
}

export default NoAccess
