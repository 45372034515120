import {Button} from '@mui/material'
import {ButtonShadow} from 'style/button'

import PropTypes from 'prop-types'

// eslint-disable-next-line react/prop-types
const Buttons = ({
	title,
	onClick,
	color,
	disable,
	type,
	startIcon,
	endIcon,
	sx,
	outlined,
	size
}) => (
	<Button
		disableElevation
		variant={outlined ? 'outlined' : 'contained'}
		type={type ?? 'submit'}
		color={color ?? 'secondary'}
		size={size ?? 'small'}
		sx={{...ButtonShadow(), borderRadius: '12px', textTransform: 'none', px: 2, ...sx}}
		onClick={onClick}
		disabled={disable}
		startIcon={startIcon}
		endIcon={endIcon}>
		{title}
	</Button>
)

Buttons.propTypes = {
	title: PropTypes.string.isRequired,
	color: PropTypes.string
}

export default Buttons
