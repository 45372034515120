export const menuItems = {
	items: [
		// PENGESAHAN
		// IZIN MENIKAH - PENGESAHAN
		{
			url: '/pengesahan-izin-menikah',
			permissions: ['Detail - permission.IzinMenikah']
		},
		{
			url: '/pengesahan-izin-menikah/:id',
			permissions: ['Ubah Data - permission.IzinMenikah', 'Detail - permission.IzinMenikah']
		},
		// CACAH JIWA - PENGESAHAN
		{
			url: '/pengesahan-cacah-jiwa/kelahiran',
			permissions: ['Detail - permission.CacahJiwaKelahiran']
		},
		{
			url: '/pengesahan-cacah-jiwa/pengangkatan-anak',
			permissions: ['Detail - permission.CacahJiwaAnakAngkat']
		},
		{
			url: '/pengesahan-cacah-jiwa/pernikahan',
			permissions: ['Detail - permission.CacahJiwaPernikahan']
		},
		{
			url: '/pengesahan-cacah-jiwa/perceraian',
			permissions: ['Detail - permission.CacahJiwaPerceraian']
		},
		{
			url: '/pengesahan-cacah-jiwa/kematian',
			permissions: ['Detail - permission.CacahJiwaKematian']
		},
		{
			url: '/pengesahan-cacah-jiwa/kelahiran/:id',
			permissions: [
				'Ubah Data - permission.CacahJiwaKelahiran',
				'Detail - permission.CacahJiwaKelahiran'
			]
		},
		{
			url: '/pengesahan-cacah-jiwa/pengangkatan-anak/:id',
			permissions: [
				'Ubah Data - permission.CacahJiwaAnakAngkat',
				'Detail - permission.CacahJiwaAnakAngkat'
			]
		},
		{
			url: '/pengesahan-cacah-jiwa/pernikahan/:id',
			permissions: [
				'Ubah Data - permission.CacahJiwaPernikahan',
				'Detail - permission.CacahJiwaPernikahan'
			]
		},
		{
			url: '/pengesahan-cacah-jiwa/perceraian/:id',
			permissions: [
				'Ubah Data - permission.CacahJiwaPerceraian',
				'Detail - permission.CacahJiwaPerceraian'
			]
		},
		{
			url: '/pengesahan-cacah-jiwa/kematian/:id',
			permissions: [
				'Ubah Data - permission.CacahJiwaKematian',
				'Detail - permission.CacahJiwaKematian'
			]
		},
		// CUTI - PENGESAHAN
		{
			url: '/pengesahan-cuti/tahunan',
			permissions: ['Detail - permission.CutiTahunan']
		},
		{
			url: '/pengesahan-cuti/besar',
			permissions: ['Detail - permission.CutiBesar']
		},
		{
			url: '/pengesahan-cuti/sakit',
			permissions: ['Detail - permission.CutiSakit']
		},
		{
			url: '/pengesahan-cuti/melahirkan',
			permissions: ['Detail - permission.CutiMelahirkan']
		},
		{
			url: '/pengesahan-cuti/karena-alasan-penting',
			permissions: ['Detail - permission.CutiAlasanPenting']
		},
		{
			url: '/pengesahan-cuti/cltn',
			permissions: ['Detail - permission.CLTN']
		},
		{
			url: '/pengesahan-cuti/perpanjangan-cltn',
			permissions: ['Detail - permission.CLTN']
		},
		{
			url: '/pengesahan-cuti/lapor-selesai-cltn',
			permissions: ['Detail - permission.CLTN']
		},
		{
			url: '/pengesahan-cuti/cltn-aktif',
			permissions: ['Detail - permission.CLTN']
		},
		{
			url: '/pengesahan-cuti/tahunan/:id',
			permissions: ['Ubah Data - permission.CutiTahunan', 'Detail - permission.CutiTahunan']
		},
		{
			url: '/pengesahan-cuti/besar/:id',
			permissions: ['Ubah Data - permission.CutiBesar', 'Detail - permission.CutiBesar']
		},
		{
			url: '/pengesahan-cuti/sakit/:id',
			permissions: ['Ubah Data - permission.CutiSakit', 'Detail - permission.CutiSakit']
		},
		{
			url: '/pengesahan-cuti/melahirkan/:id',
			permissions: [
				'Ubah Data - permission.CutiMelahirkan',
				'Detail - permission.CutiMelahirkan'
			]
		},
		{
			url: '/pengesahan-cuti/karena-alasan-penting/:id',
			permissions: [
				'Ubah Data - permission.CutiAlasanPenting',
				'Detail - permission.CutiAlasanPenting'
			]
		},
		{
			url: '/pengesahan-cuti/cltn/:id',
			permissions: ['Ubah Data - permission.CLTN', 'Detail - permission.CLTN']
		},
		{
			url: '/pengesahan-cuti/perpanjangan-cltn/:id',
			permissions: ['Ubah Data - permission.CLTN', 'Detail - permission.CLTN']
		},
		{
			url: '/pengesahan-cuti/lapor-selesai-cltn/:id',
			permissions: ['Ubah Data - permission.CLTN', 'Detail - permission.CLTN']
		},
		{
			url: '/pengesahan-cuti/cltn-aktif/:id',
			permissions: ['Ubah Data - permission.CLTN', 'Detail - permission.CLTN']
		},

		// PENGAJUAN
		// IZIN MENIKAH - PENGAJUAN
		{
			url: '/pengajuan-izin-menikah',
			permissions: ['Tambah Data - permission.IzinMenikah']
		},

		// CACAH JIWA - PENGAJUAN
		{
			url: '/pengajuan-cacah-jiwa/kelahiran',
			permissions: ['Tambah Data - permission.CacahJiwaKelahiran']
		},
		{
			url: '/pengajuan-cacah-jiwa/pengangkatan-anak',
			permissions: ['Tambah Data - permission.CacahJiwaAnakAngkat']
		},
		{
			url: '/pengajuan-cacah-jiwa/pernikahan',
			permissions: ['Tambah Data - permission.CacahJiwaPernikahan']
		},
		{
			url: '/pengajuan-cacah-jiwa/perceraian',
			permissions: ['Tambah Data - permission.CacahJiwaPerceraian']
		},
		{
			url: '/pengajuan-cacah-jiwa/kematian',
			permissions: ['Tambah Data - permission.CacahJiwaKematian']
		},

		// CUTI - PENGAJUAN
		{
			url: '/pengajuan-cuti/cuti-tahunan',
			permissions: ['Tambah Data - permission.CutiTahunan']
		},
		{
			url: '/pengajuan-cuti/cuti-besar',
			permissions: ['Tambah Data - permission.CutiBesar']
		},
		{
			url: '/pengajuan-cuti/cuti-sakit',
			permissions: ['Tambah Data - permission.CutiSakit']
		},
		{
			url: '/pengajuan-cuti/cuti-melahirkan',
			permissions: ['Tambah Data - permission.CutiMelahirkan']
		},
		{
			url: '/pengajuan-cuti/cuti-karena-alasan-penting',
			permissions: ['Tambah Data - permission.CutiAlasanPenting']
		},
		{
			url: '/pengajuan-cuti/cuti-di-luar-tanggungan-negara',
			permissions: ['Tambah Data - permission.CLTN']
		},

		// RWY PENGAJUAN CUTI
		{
			url: '/riwayat-pengajuan-cuti',
			permissions: [
				'Tambah Data - permission.CutiTahunan',
				'Tambah Data - permission.CutiBesar',
				'Tambah Data - permission.CutiSakit',
				'Tambah Data - permission.CutiMelahirkan',
				'Tambah Data - permission.CutiAlasanPenting',
				'Tambah Data - permission.CLTN'
			]
		},

		// MASTER DATA
		{
			url: '/master-data/master-cuti-pegawai',
			permissions: ['Daftar Paginasi - permission.MasterCutiPegawai']
		},
		{
			url: '/master-data/master-kgb',
			permissions: ['Daftar Paginasi - permission.MasterKGB']
		},
		{
			url: '/master-data/master-role',
			permissions: ['Daftar Paginasi - permission.MasterRole']
		},
		{
			url: '/master-data/master-role/form',
			permissions: ['Tambah Data - permission.MasterRole', 'Ubah Data - permission.MasterRole']
		},
		{
			url: '/master-data/master-user',
			permissions: ['Daftar Paginasi - permission.MasterUser']
		},

		// LOG
		{
			url: '/log-aktivitas',
			permissions: ['Daftar Paginasi - permission.LogAktivitas']
		}
	]
}

export const getDefaultRoute = permissions => {
	const menuItem = menuItems.items.find(
		item =>
			Array.isArray(item.permissions) &&
			item.permissions.some(
				permission =>
					Array.isArray(permissions) &&
					permissions.some(userPerm => userPerm.permission_name === permission)
			)
	)
	return menuItem ? menuItem.url : '/*'
}
