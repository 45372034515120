import {useState} from 'react'
import {useTheme} from '@mui/material/styles'
import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Typography
} from '@mui/material'
import {Formik} from 'formik'
import {useNavigate} from 'react-router-dom'
import {TextFieldStyle1, TextFieldStyle2} from 'style/textField'
import {getPegawaibyNIP} from 'services/api/tbl'
import {IconEye, IconEyeOff} from '@tabler/icons'
import {useDispatch} from 'react-redux'
import {LOGIN, SNACKBAR_OPEN, UPDATE_USER_DETAILS} from 'store/actions'

import * as Yup from 'yup'
import useScriptRef from 'hooks/useScriptRef'
import PropTypes from 'prop-types'
import LupaKataSandi from '../../../../components/card/LupaKataSandi'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Loader from 'ui-component/Loader'
import Buttons from 'components/button/Buttons'
import {storageKey} from 'store/constant'
import {getDefaultRoute} from 'routes/routeHelper'

// ============================|| FIREBASE - LOGIN ||============================ //

// eslint-disable-next-line react/prop-types
const FirebaseLogin = ({isUnderMaintenance, ...others}) => {
	const theme = useTheme()
	const scriptedRef = useScriptRef()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [open, setOpen] = useState(false)

	const handleClickShowPassword = () => setShowPassword(!showPassword)
	const handleMouseDownPassword = event => event.preventDefault()

	const emailValidation = value => {
		if (!value && !open) {
			return 'Harap masukkan alamat email'
		}
		if (value.endsWith('@gmail.com')) {
			return true
		}
		if (value.endsWith('@')) {
			return 'Harap masukkan alamat domain email'
		}
		if (value.includes('@') && !value.endsWith('@kemlu.go.id')) {
			return 'Harap lengkapi alamat domain (@kemlu.go.id)'
		}
		if (!value.includes('@')) {
			return true
		}
		try {
			Yup.string().email('Masukkan alamat email yang valid').validateSync(value)
			return true
		} catch (error) {
			return 'Alamat email tidak valid'
		}
	}

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.test('email', 'Harus alamat email yang valid', value => {
				const errorMessage = emailValidation(value)
				return errorMessage === true
					? true
					: new Yup.ValidationError(errorMessage, value, 'email')
			})
			.max(255)
			.required('Harap masukkan alamat email'),
		password: Yup.string().max(255).required('Harap masukkan kata sandi')
	})

	const fetchPegawai = async nip => {
		setLoading(true)
		await getPegawaibyNIP({nip})
			.then(res => {
				if (res && res.success && res.data) {
					const noKK = res?.data?.no_kk ?? ''
					const gender = res?.data?.kelamin?.toLowerCase().includes('l')
						? 'LAKI-LAKI'
						: res?.data?.kelamin?.toLowerCase().includes('p')
						? 'PEREMPUAN'
						: undefined
					const masaKerja = res?.data?.masa_kerja ?? null
					const isCLTN =
						res?.data?.jabatan?.toLowerCase().endsWith('tanggungan negara') ?? false
					const isPerwakilan = res?.data?.penempatan?.toLowerCase() === 'perwakilan' ?? false

					dispatch({
						type: UPDATE_USER_DETAILS,
						payload: {
							noKK,
							gender,
							masaKerja,
							isCLTN,
							isPerwakilan,
							isExist: true
						}
					})
				} else {
					console.error('An error occurred:', res.message)
					dispatch({
						type: UPDATE_USER_DETAILS,
						payload: {
							isExist: false
						}
					})
				}
			})
			.catch(e => {
				console.error(e)
				dispatch({
					type: UPDATE_USER_DETAILS,
					payload: {
						isExist: false
					}
				})
			})
			.finally(() => {
				setLoading(false)
				return null
			})
	}

	return (
		<>
			{loading && <Loader />}
			{open === 'lupaKataSandi' && (
				<LupaKataSandi open={open === 'lupaKataSandi'} handleClose={() => setOpen(false)} />
			)}

			<Grid item xs={12} container alignItems='center' justifyContent='center'>
				<Typography variant='subtitle1' color='rgba(64, 64, 65, 1)'>
					Masuk
				</Typography>
			</Grid>

			<Formik
				initialValues={{
					email: '',
					password: '',
					submit: null
				}}
				validationSchema={validationSchema}
				onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
					try {
						setLoading(true)
						if (!navigator.onLine) {
							setErrors({
								submit: 'Tidak ada koneksi internet. Periksa koneksi Anda dan coba lagi.'
							})
							setSubmitting(false)
							return
						}
						if (isUnderMaintenance) {
							setErrors({
								submit: 'Sistem saat ini sedang dalam perbaikan. Silahkan coba lagi nanti.'
							})
							setSubmitting(false)
							return
						}
						const apiUrl = `${process.env.REACT_APP_API_URL}/auth/login`
						const payload = {identifier: values.email, password: values.password}
						await axios
							.post(apiUrl, payload, {
								headers: {
									'Access-Control-Allow-Origin': '*',
									'Content-Type': 'application/json'
								}
							})
							.then(async res => {
								if (res && res?.data && res.data?.data) {
									const data = res.data.data
									const userInfo = jwtDecode(data?.token_access ?? data?.token_access)

									if (userInfo) {
										const userNip = data?.nip || data?.auth_data?.NIP
										const userRoleName = data?.role?.name || data?.role
										const token =
											res?.data?.data?.token_access ?? res?.data?.data?.token_access
										const pgwPermission = [
											{
												permission_id: '97b3b181-1db1-4124-81a0-e45a3de91554',
												permission_module: 'permission.IzinMenikah',
												permission_name: 'Tambah Data - permission.IzinMenikah'
											},
											{
												permission_id: 'e70712eb-b8ac-4c15-b8b7-3efee7aa656e',
												permission_module: 'permission.CacahJiwaKelahiran',
												permission_name: 'Tambah Data - permission.CacahJiwaKelahiran'
											},
											{
												permission_id: 'f675dd32-853d-43c8-80d6-b70126d82638',
												permission_module: 'permission.CacahJiwaAnakAngkat',
												permission_name: 'Tambah Data - permission.CacahJiwaAnakAngkat'
											},
											{
												permission_id: 'eecfade4-7bfd-40f7-8540-f67883f31a5a',
												permission_module: 'permission.CacahJiwaPernikahan',
												permission_name: 'Tambah Data - permission.CacahJiwaPernikahan'
											},
											{
												permission_id: 'dfd986af-0d03-4191-892f-8803764debc9',
												permission_module: 'permission.CacahJiwaPerceraian',
												permission_name: 'Tambah Data - permission.CacahJiwaPerceraian'
											},
											{
												permission_id: '48dea516-8a5a-4781-a2fc-900de47530e1',
												permission_module: 'permission.CacahJiwaKematian',
												permission_name: 'Tambah Data - permission.CacahJiwaKematian'
											},
											{
												permission_id: '2aa34ec0-592e-4602-8dfc-363ed8813bd2',
												permission_module: 'permission.CutiTahunan',
												permission_name: 'Tambah Data - permission.CutiTahunan'
											},
											{
												permission_id: '38b09204-4bf5-48b0-9115-8c6e70db9f8c',
												permission_module: 'permission.CutiBesar',
												permission_name: 'Tambah Data - permission.CutiBesar'
											},
											{
												permission_id: '2edc5bfe-03d8-4137-bbc0-121d7db8a30e',
												permission_module: 'permission.CutiSakit',
												permission_name: 'Tambah Data - permission.CutiSakit'
											},
											{
												permission_id: 'b77353f7-0f37-4bb7-a372-d9f60f6e656c',
												permission_module: 'permission.CutiMelahirkan',
												permission_name: 'Tambah Data - permission.CutiMelahirkan'
											},
											{
												permission_id: '66bdc9fe-ad2d-4e12-bda5-5d8ddd05c60e',
												permission_module: 'permission.CutiAlasanPenting',
												permission_name: 'Tambah Data - permission.CutiAlasanPenting'
											},
											{
												permission_id: '214dac58-2ffe-468b-b856-bd59ee38b4a2',
												permission_module: 'permission.CLTN',
												permission_name: 'Tambah Data - permission.CLTN'
											},

											{
												permission_id: '66ee5d37-63cc-4f5d-ae50-01dc7a4b6ecf',
												permission_module: 'permission.KenaikanGajiBerkala',
												permission_name: 'Ubah Data - permission.KenaikanGajiBerkala'
											},
											{
												permission_id: '46e12d53-a3d6-45d9-8258-f3ded3d80017',
												permission_module: 'permission.CacahJiwaKelahiran',
												permission_name: 'Ubah Data - permission.CacahJiwaKelahiran'
											},
											{
												permission_id: '20713e84-6ce3-4371-81f8-32509986c4eb',
												permission_module: 'permission.CacahJiwaAnakAngkat',
												permission_name: 'Ubah Data - permission.CacahJiwaAnakAngkat'
											},
											{
												permission_id: 'ec911560-2091-4bb8-b8ef-1492404d1e50',
												permission_module: 'permission.CacahJiwaPernikahan',
												permission_name: 'Ubah Data - permission.CacahJiwaPernikahan'
											},
											{
												permission_id: '5e04f3a9-dfd0-4604-8936-09cd4586b0db',
												permission_module: 'permission.CacahJiwaPerceraian',
												permission_name: 'Ubah Data - permission.CacahJiwaPerceraian'
											},
											{
												permission_id: '04391c75-5486-41b3-89b9-e51e6c976a0e',
												permission_module: 'permission.CacahJiwaKematian',
												permission_name: 'Ubah Data - permission.CacahJiwaKematian'
											},
											{
												permission_id: 'ea860821-4c78-4ab4-bc1b-f3596ed09fea',
												permission_module: 'permission.CutiTahunan',
												permission_name: 'Ubah Data - permission.CutiTahunan'
											},
											{
												permission_id: '3e43d921-c9a8-4666-9a79-54591c572720',
												permission_module: 'permission.CutiBesar',
												permission_name: 'Ubah Data - permission.CutiBesar'
											},
											{
												permission_id: 'e6c7a02e-ef04-4076-aec4-c4e1f03d583c',
												permission_module: 'permission.CutiSakit',
												permission_name: 'Ubah Data - permission.CutiSakit'
											},
											{
												permission_id: '51bf3168-29a7-413b-89f1-b0338751c828',
												permission_module: 'permission.CutiMelahirkan',
												permission_name: 'Ubah Data - permission.CutiMelahirkan'
											},
											{
												permission_id: 'bd0fea69-7d14-45f3-a9a7-314dad26b979',
												permission_module: 'permission.CutiAlasanPenting',
												permission_name: 'Ubah Data - permission.CutiAlasanPenting'
											},
											{
												permission_id: '0dc18ae8-006c-4aed-91cc-98f0cc377e76',
												permission_module: 'permission.CLTN',
												permission_name: 'Ubah Data - permission.CLTN'
											},
											{
												permission_id: '4aee91b1-e0c8-4eef-83ed-c5459e85a90c',
												permission_module: 'permission.KenaikanGajiBerkala',
												permission_name: 'Detail - permission.KenaikanGajiBerkala'
											},
											{
												permission_id: '0709b2da-8bf7-4372-90f9-c5e1da4943e5',
												permission_module: 'permission.CacahJiwaKelahiran',
												permission_name: 'Detail - permission.CacahJiwaKelahiran'
											},
											{
												permission_id: '5c35b5eb-7a6d-42aa-b2b3-43679df9dd50',
												permission_module: 'permission.CacahJiwaAnakAngkat',
												permission_name: 'Detail - permission.CacahJiwaAnakAngkat'
											},
											{
												permission_id: '7b05771e-ae03-495a-92bf-dc169c5b920a',
												permission_module: 'permission.CacahJiwaPernikahan',
												permission_name: 'Detail - permission.CacahJiwaPernikahan'
											},
											{
												permission_id: 'e59e92f6-235c-4d97-a178-f4a8a05e9c70',
												permission_module: 'permission.CacahJiwaPerceraian',
												permission_name: 'Detail - permission.CacahJiwaPerceraian'
											},
											{
												permission_id: '147da6b0-8c17-4a72-b18e-c94c5fe1ea36',
												permission_module: 'permission.CacahJiwaKematian',
												permission_name: 'Detail - permission.CacahJiwaKematian'
											},
											{
												permission_id: '41e2b354-bebc-4cb7-b499-71446c9bd7c1',
												permission_module: 'permission.CutiTahunan',
												permission_name: 'Detail - permission.CutiTahunan'
											},
											{
												permission_id: 'e5b1e824-f146-4809-93d8-dd378fc138b2',
												permission_module: 'permission.CutiBesar',
												permission_name: 'Detail - permission.CutiBesar'
											},
											{
												permission_id: '517b4bc4-49c6-4073-a9bd-4663e894fe44',
												permission_module: 'permission.CutiSakit',
												permission_name: 'Detail - permission.CutiSakit'
											},
											{
												permission_id: 'b8b653ea-a007-41f0-9a5c-0c4abc17b35f',
												permission_module: 'permission.CutiMelahirkan',
												permission_name: 'Detail - permission.CutiMelahirkan'
											},
											{
												permission_id: 'ac490df3-71cf-45ee-950b-4b5092142039',
												permission_module: 'permission.CutiAlasanPenting',
												permission_name: 'Detail - permission.CutiAlasanPenting'
											},
											{
												permission_id: '3c4009e0-7f9e-497f-8e26-13b83d4ad0e1',
												permission_module: 'permission.CLTN',
												permission_name: 'Detail - permission.CLTN'
											},

											{
												permission_id: '5923ef71-770e-4de4-aabc-7c2320a521d8',
												permission_module: 'permission.IzinMenikah',
												permission_name: 'Hapus Data - permission.IzinMenikah'
											},
											{
												permission_id: 'ce83dab9-692d-41ba-9b5b-592a7da6fde7',
												permission_module: 'permission.CacahJiwaKelahiran',
												permission_name: 'Hapus Data - permission.CacahJiwaKelahiran'
											},
											{
												permission_id: '5307c175-9403-4f9b-bf0d-63598d4c6036',
												permission_module: 'permission.CacahJiwaAnakAngkat',
												permission_name: 'Hapus Data - permission.CacahJiwaAnakAngkat'
											},
											{
												permission_id: 'c3c331a2-e4a1-4cd7-a4fc-e0c8346e1eea',
												permission_module: 'permission.CacahJiwaPernikahan',
												permission_name: 'Hapus Data - permission.CacahJiwaPernikahan'
											},
											{
												permission_id: '3b584a0f-ba27-4f4f-a91d-b53f198e45e8',
												permission_module: 'permission.CacahJiwaPerceraian',
												permission_name: 'Hapus Data - permission.CacahJiwaPerceraian'
											},
											{
												permission_id: 'e1df9961-9ba0-494c-8e8c-2e7db18c6338',
												permission_module: 'permission.CacahJiwaKematian',
												permission_name: 'Hapus Data - permission.CacahJiwaKematian'
											},
											{
												permission_id: 'ca2631a4-0a66-4d3c-9f12-0b11320f81b6',
												permission_module: 'permission.CutiTahunan',
												permission_name: 'Hapus Data - permission.CutiTahunan'
											},
											{
												permission_id: 'e61be3cc-7b85-484a-ab10-b1d9648d596a',
												permission_module: 'permission.CutiBesar',
												permission_name: 'Hapus Data - permission.CutiBesar'
											},
											{
												permission_id: '09921e78-eef5-45a3-8218-0434104a8cd8',
												permission_module: 'permission.CutiSakit',
												permission_name: 'Hapus Data - permission.CutiSakit'
											},
											{
												permission_id: '3cd9a2b4-c317-4199-ac35-3338ca39736d',
												permission_module: 'permission.CutiMelahirkan',
												permission_name: 'Hapus Data - permission.CutiMelahirkan'
											},
											{
												permission_id: '63cb912c-efb1-4bef-812e-2e5349e39e46',
												permission_module: 'permission.CutiAlasanPenting',
												permission_name: 'Hapus Data - permission.CutiAlasanPenting'
											},
											{
												permission_id: '2ff54a42-9c7a-4a97-a26d-c8c6fa23fe1f',
												permission_module: 'permission.CLTN',
												permission_name: 'Hapus Data - permission.CLTN'
											}
										]

										localStorage.setItem(storageKey.USER_TOKEN, token)
										dispatch({
											type: LOGIN,
											payload: {
												user: {
													id: data?.id || '',
													nip: userNip || '',
													nik: data?.nik || '',
													name: data?.nama || data?.auth_data?.Nama || '',
													unit_kerja: data?.unit_kerja || '',
													jabatan: data?.jabatan || '',
													status_pegawai: data?.status_pegawai || null
												},
												userInfo: JSON.stringify(userInfo),
												role: {
													name: userRoleName || '',
													permissions:
														userRoleName.toLowerCase() === 'pegawai'
															? pgwPermission
															: data?.role?.role_permission?.length > 0
															? data?.role?.role_permission.map(item => ({
																	permission_id: item.permission_id || '',
																	permission_module: item.permission_module || '',
																	permission_name: item.permission_name || ''
															  }))
															: []
												}
											}
										})
										fetchPegawai(userNip)
										setTimeout(() => {
											const userPermissions =
												userRoleName.toLowerCase() === 'pegawai'
													? pgwPermission
													: data?.role?.role_permission || []
											const defaultRoute = getDefaultRoute(userPermissions)

											if (defaultRoute) {
												navigate(defaultRoute, {replace: true})
											} else {
												navigate('*', {replace: true})
											}
										}, [1000])
									}
								}
								// WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
								// Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
								// To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
								// github issue: https://github.com/formium/formik/issues/2430
							})
							.catch(err => {
								if (scriptedRef.current) {
									setStatus({success: false})
									setErrors({submit: err?.response?.data?.message})
									setSubmitting(false)
								}
							})
					} catch (err) {
						console.error(err)
						dispatch({
							type: SNACKBAR_OPEN,
							open: true,
							message:
								err.response?.data?.message ?? 'Login tidak berhasil. Harap coba lagi.',
							alertSeverity: 'warning'
						})
						if (scriptedRef.current) {
							setStatus({success: false})
							setErrors({
								submit: err?.response?.message ?? 'Login tidak berhasil. Harap coba lagi.'
							})
							setSubmitting(false)
						}
					} finally {
						setLoading(false)
					}
				}}>
				{({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
					<form noValidate onSubmit={handleSubmit} {...others}>
						<FormControl
							fullWidth
							error={Boolean(touched.email && errors.email)}
							sx={{...theme.typography.customInput}}>
							<InputLabel htmlFor='outlined-adornment-email-login'>
								Email / Username
							</InputLabel>
							<OutlinedInput
								id='outlined-adornment-email-login'
								type='email'
								value={values.email}
								name='email'
								onBlur={handleBlur}
								onChange={handleChange}
								inputProps={{}}
								disabled={isSubmitting}
								sx={TextFieldStyle1}
							/>
							{/* <FormHelperText id="standard-weight-helper-text-email-login">
                                <Typography variant="caption" color="gray">
                                    Dapat ditulis tanpa domain (@kemlu.go.id)
                                </Typography>
                            </FormHelperText> */}
							{touched.email && errors.email && (
								<FormHelperText error id='standard-weight-helper-text-email-login'>
									{errors.email}
								</FormHelperText>
							)}
						</FormControl>

						<FormControl
							fullWidth
							error={Boolean(touched.password && errors.password)}
							sx={{...theme.typography.customInput}}>
							<InputLabel htmlFor='outlined-adornment-password-login'>Kata Sandi</InputLabel>
							<OutlinedInput
								id='outlined-adornment-password-login'
								type={showPassword ? 'text' : 'password'}
								value={values.password}
								name='password'
								onBlur={handleBlur}
								onChange={handleChange}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'
											size='small'>
											{showPassword ? <IconEye /> : <IconEyeOff />}
										</IconButton>
									</InputAdornment>
								}
								label='Kata Sandi'
								inputProps={{}}
								disabled={isSubmitting}
								sx={TextFieldStyle2}
							/>
							{touched.password && errors.password && (
								<FormHelperText error id='standard-weight-helper-text-password-login'>
									{errors.password}
								</FormHelperText>
							)}
						</FormControl>
						<Typography
							variant='subtitle1'
							onClick={() => setOpen('lupaKataSandi')}
							color='secondary'
							sx={{cursor: 'pointer', textAlign: 'left', width: 'fit-content'}}>
							Lupa kata sandi?
						</Typography>
						{errors.submit && (
							<Box>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box sx={{mt: 1}}>
							<Buttons size='large' title='Masuk' sx={{width: '100%'}} />
						</Box>
					</form>
				)}
			</Formik>
		</>
	)
}

FirebaseLogin.propTypes = {
	loginProp: PropTypes.number
}

export default FirebaseLogin
