import {combineReducers} from 'redux'
import {createTransform, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// reducer import
import customizationReducer from './customizationReducer'
import snackbarReducer from './snackbarReducer'
import accountReducer from './accountReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const accountTransform = createTransform(
	inboundState => {
		if (inboundState.isLoggedIn) {
			return {
				...inboundState,
				user: inboundState.user,
				userInfo: inboundState.userInfo,
				role: inboundState.role
			}
		}
		return {}
	},
	outboundState => {
		return outboundState
	},
	{whitelist: ['account']}
)

const reducer = combineReducers({
	customization: customizationReducer,
	snackbar: snackbarReducer,
	account: persistReducer(
		{
			key: 'data',
			storage,
			keyPrefix: 'elayanan@',
			transforms: [accountTransform],
			whitelist: ['user', 'userInfo', 'role', 'isLoggedIn', 'isInitialized']
		},
		accountReducer
	)
})

export default reducer
