import {useTheme} from '@mui/material/styles'

export const TextFieldStyle1 = () => {
	const theme = useTheme()

	return {
		width: '100%',
		'& input, & textarea, & .MuiSelect-select, & .MuiAutocomplete-inputRoot, & .MuiInputBase-root': {
			backgroundColor: theme.palette.grey[100],
			border: 'none'
		},
		'& .MuiOutlinedInput-root': {
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.secondary.dark
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.secondary.dark
			}
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent'
		}
	}
}

export const TextFieldStyle2 = () => {
	const theme = useTheme()

	return {
		width: '100%',
		borderRadius: '12px',
		backgroundColor: theme.palette.grey[100],
		'& .MuiOutlinedInput-root, & .MuiOutlinedInput-input, &.MuiOutlinedInput-notchedOutline': {
			backgroundColor: theme.palette.grey[100],
			'&.Mui-focused .MuiOutlinedInput-input': {
				borderColor: theme.palette.secondary.dark,
				backgroundColor: theme.palette.grey[100]
			}
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent'
		}
	}
}
