// action - state management
import {LOGIN, LOGOUT, UPDATE_ROLE_DETAILS, UPDATE_USER_DETAILS} from './actions'

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
	user: {
		id: '',
		nip: '',
		nik: '',
		name: '',
		unit_kerja: '',
		jabatan: '',
		status_pegawai: null,
		noKK: '',
		gender: '',
		masaKerja: null,
		isCLTN: false,
		isPerwakilan: false,
		isExist: false
	},
	userInfo: '',
	role: {
		name: '',
		permissions: []
	},
	isLoggedIn: false,
	isInitialized: false
}

const accountReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN: {
			return {
				...state,
				...action.payload,
				user: {
					...state.user,
					...action.payload.user
				},
				isLoggedIn: true,
				isInitialized: true
			}
		}
		case LOGOUT: {
			return {
				...state,
				isInitialized: true,
				isLoggedIn: false,
				user: initialState.user,
				role: initialState.role,
				userInfo: ''
			}
		}
		case UPDATE_USER_DETAILS:
			return {
				...state,
				user: {
					...state.user,
					noKK: action?.payload?.noKK ?? state?.user?.noKK,
					gender: action?.payload?.gender ?? state?.user?.gender,
					masaKerja: action?.payload?.masaKerja ?? state?.user?.masaKerja,
					isCLTN: action?.payload?.isCLTN || state?.user?.isCLTN,
					isPerwakilan: action?.payload?.isPerwakilan || state?.user?.isPerwakilan,
					isExist: action?.payload?.isExist || state?.user?.isExist
				}
			}
		case UPDATE_ROLE_DETAILS:
			return {
				...state,
				role: {
					...state.role,
					name: action?.payload?.name ?? state?.role?.name,
					permissions: action?.payload?.permissions ?? state?.role?.permissions
				}
			}
		default: {
			return {...state}
		}
	}
}

export default accountReducer
