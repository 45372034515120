// action - account reducer
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const UPDATE_ROLE_DETAILS = 'UPDATE_ROLE_DETAILS'

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU'
export const MENU_OPEN = '@customization/MENU_OPEN'
export const THEME_LOCALE = '@customization/THEME_LOCALE'

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN'
export const SNACKBAR_CLOSE = '@snackbar/SNACKBAR_CLOSE'

// action - count unprocessed
export const DECREMENT_COUNT = 'DECREMENT_COUNT'
export const UPDATE_COUNTS = 'UPDATE_COUNTS'

export const apiRequest = ({url, method, contentType, payload}) => {
	return {
		type: 'API_REQUEST',
		url,
		method,
		contentType,
		payloadAPI: payload
	}
}
