import PropTypes from 'prop-types'
import {forwardRef} from 'react'
import {Card, CardContent, CardHeader, Divider} from '@mui/material'

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
	(
		{
			boxShadow,
			children,
			content = true,
			contentClass = '',
			contentSX = {},
			secondary,
			shadow,
			sx = {},
			title,
			headerSX = {},
			...others
		},
		ref
	) => (
		<Card
			ref={ref}
			{...others}
			sx={{
				borderTopLeftRadius: 12,
				borderTopRightRadius: 12,
				':hover': {
					boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
				},
				...sx
			}}>
			{/* card header and action */}
			{title && (
				<CardHeader
					sx={{p: 2, '& .MuiCardHeader-action': {mr: 0}, ...headerSX}}
					title={title}
					action={secondary}
				/>
			)}
			{/* content & header divider */}
			{title && <Divider />}

			{/* card content */}
			{content && (
				<CardContent sx={contentSX} className={contentClass}>
					{children}
				</CardContent>
			)}
			{!content && children}
		</Card>
	)
)

MainCard.propTypes = {
	border: PropTypes.bool,
	boxShadow: PropTypes.bool,
	children: PropTypes.node,
	content: PropTypes.bool,
	contentClass: PropTypes.string,
	contentSX: PropTypes.object,
	secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
	shadow: PropTypes.string,
	sx: PropTypes.object,
	headerSX: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
}

export default MainCard
