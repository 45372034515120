import MinimalLayout from 'layout/MinimalLayout'
import Login from 'views/pages/authentication/authentication/Login'

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
	path: '/',
	element: <MinimalLayout />,
	children: [
		{
			path: '/login',
			element: <Login isUnderMaintenance={false} />
		}
	]
}

export default AuthenticationRoutes
